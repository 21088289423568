//
// This file is a direct copy from the shared `document_type_generator.js`
// MAKE SURE TO KEEP THESE FILES IDENTICAL WHEN CHANGES ARE MADE!
// VVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV

const parseFallbackNlLocalizationFromDocumentType = (docTypeId) => {
  if (docTypeId.startsWith('DH-API-')) return docTypeId.substring(7);
  if (docTypeId.startsWith('DH-')) return docTypeId.substring(3);
  if (docTypeId.startsWith('DS-')) return docTypeId.substring(3).replace(/-/g, ' ');
  if (docTypeId.startsWith('DS2-')) return docTypeId.substring(4);
  if (docTypeId.startsWith('STB-')) return docTypeId.substring(4);
  if (docTypeId.startsWith('DOJ-')) return docTypeId.substring(4);
  if (docTypeId.startsWith('SB-')) return 'Stakeholderbericht';
  if (docTypeId.startsWith('MD-')) return 'Mediabericht';
  if (
    docTypeId.startsWith('EUROPARL-')
    || docTypeId.startsWith('EUROCOM-')
    || docTypeId.startsWith('EUROCOU-')
  ) return 'EU document';
  if (docTypeId.startsWith('DCAB-')) return 'Cabinet document';
  if (docTypeId.startsWith('CC-') || docTypeId.startsWith('PC-')) return docTypeId.split('-')[2];
  return docTypeId; // Last resort fallback should not be used whenever possible.
};

const findBasicLabelsByDocumentType = (docTypeId) => {
  if (docTypeId.startsWith('DH-')) return ['gov:NL', 'govBranch:Tweede Kamer']; // Also works for DH-API-
  if (docTypeId.startsWith('DS-')) return ['gov:NL', 'govBranch:Eerste Kamer'];
  if (docTypeId.startsWith('DS2-')) return ['gov:NL', 'govBranch:Eerste Kamer'];
  if (docTypeId.startsWith('STB-')) return ['gov:NL', 'publication:Staatsblad'];
  if (docTypeId.startsWith('DOJ-')) return ['gov:NL', 'publication:Staatscourant'];
  if (docTypeId.startsWith('SB-')) return ['publicationCategory:Stakeholder'];
  if (docTypeId.startsWith('MD-')) return ['publicationCategory:Media'];
  if (
    docTypeId.startsWith('EUROPARL-')
    || docTypeId.startsWith('EUROCOM-')
    || docTypeId.startsWith('EUROCOU-')
  ) return ['gov:EU'];
  if (docTypeId.startsWith('DCAB-')) return ['gov:NL', 'govBranch:Cabinet'];
  if (docTypeId.startsWith('CC-')) return ['organizationType:municipality'];
  if (docTypeId.startsWith('PC-')) return ['organizationType:province'];
  return []; // Last resort fallback should not be used whenever possible.
};

const parseFallbackModuleRequirementsFromDocumentType = (docTypeId) => {
  if (docTypeId.startsWith('DH-DEBATE-')) return [{ code: 'MC16' }];
  if (docTypeId.startsWith('DH-')) return [{ code: 'MC11' }];
  if (docTypeId.startsWith('DS-')) return [{ code: 'MC02' }];
  if (docTypeId.startsWith('DS2-')) return [{ code: 'MC02' }];
  if (docTypeId.startsWith('STB-')) return [{ code: 'MC06' }];
  if (docTypeId.startsWith('DOJ-')) return [{ code: 'MC10' }];
  if (docTypeId.startsWith('DCAB-')) return [{ code: 'MC12' }];
  if (docTypeId.startsWith('CC-') || docTypeId.startsWith('PC-')) return [{ code: 'MC13' }];
  if (docTypeId.startsWith('DM-Nieuws')) return [{ code: 'MC14' }];
  if (
    docTypeId.startsWith('EUROPARL-')
    || docTypeId.startsWith('EUROCOM-')
  ) {
    return [{
      code: 'MC07',
      configuration: {
        euECEPEnabled: true,
      },
    }];
  }
  if (docTypeId.startsWith('EUROCOU-')) {
    return [{
      code: 'MC07',
      configuration: {
        euECouEnabled: true,
      },
    }];
  }
  return null;
};

const parseFallbackWatsonPipelineFromDocumentType = (docTypeId) => {
  if (
    docTypeId.startsWith('EUROPARL-')
    || docTypeId.startsWith('EUROCOM-')
    || docTypeId.startsWith('EUROCOU-')
    || docTypeId.startsWith('SB-EU-')
    || docTypeId.startsWith('MD-EU-')
  ) return 'euPipeline';

  if (
    docTypeId.startsWith('DH-')
    || docTypeId.startsWith('DS-')
    || docTypeId.startsWith('DS2-')
    || docTypeId.startsWith('STB-')
    || docTypeId.startsWith('DOJ-')
    || docTypeId.startsWith('SB-') // excluding SB-EU-!
    || docTypeId.startsWith('MD-') // excluding MD-EU-!
    || docTypeId.startsWith('DCAB-')
    || docTypeId.startsWith('CC-')
    || docTypeId.startsWith('PC-')
  ) return 'document';

  return undefined;
};

// Generate a basic config for types we do now have in the config.
// This is a fallback behavior only, we should add these types to the
// config when possible.
const generateConfigForUnknownDocumentType = (docTypeId) => ({
  labels: [
    ...findBasicLabelsByDocumentType(docTypeId),
    'specialLabel:Unknown documentType',
  ],
  localization: {
    nl: parseFallbackNlLocalizationFromDocumentType(docTypeId),
  },
  requiredModules: parseFallbackModuleRequirementsFromDocumentType(docTypeId),
  watsonPipeline: parseFallbackWatsonPipelineFromDocumentType(docTypeId),
  annotatorPipelines: [parseFallbackWatsonPipelineFromDocumentType(docTypeId)],
});

module.exports = {
  parseFallbackNlLocalizationFromDocumentType,
  findBasicLabelsByDocumentType,
  parseFallbackModuleRequirementsFromDocumentType,
  parseFallbackWatsonPipelineFromDocumentType,
  generateConfigForUnknownDocumentType,
};

// ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
// This file is a direct copy from the shared `document_type_generator.js`
// MAKE SURE TO KEEP THESE FILES IDENTICAL WHEN CHANGES ARE MADE!
//
