import {
  ref,
  computed,
  onMounted,
  watch,
} from 'vue';
import { useStore } from '@/helpers/storeHelper';

const {
  setDocumentTypesConfig,
  getDocTypeByDocTypeId,
  getLocalizationForDocTypeId,
  findDocumentTypesByLabels,
  modulesMeetDocumentTypeRequirements,
  getPrunedDocumentTypesByModules,
  getAllSupportedEuroparlCodes,
  getAllEuroparlDocumentTypesPerInstitution,
} = require('./services/document_type_service');

export default function useDocumentTypes() {
  const documentTypesMixinReady = ref(false);
  const store = useStore();

  const userFilters = computed(() => store.getters.userFilters);

  onMounted(() => {
    if (userFilters.value.documentTypesConfig) {
      setDocumentTypesConfig(userFilters.value.documentTypesConfig);
      documentTypesMixinReady.value = true;
    }
    // If it is not loaded after 5 seconds, use the fallback behaviour.
    setTimeout(() => { documentTypesMixinReady.value = true; }, 5000);
  });

  watch(
    userFilters,
    (newValue) => {
      if (newValue.documentTypesConfig) {
        setDocumentTypesConfig(newValue.documentTypesConfig);
        documentTypesMixinReady.value = true;
      }
    },
    { deep: true },
  );

  return {
    // userFilters,
    documentTypesMixinReady,
    getDocTypeByDocTypeId,
    getLocalizationForDocTypeId,
    findDocumentTypesByLabels,
    modulesMeetDocumentTypeRequirements,
    getPrunedDocumentTypesByModules,
    getAllSupportedEuroparlCodes,
    getAllEuroparlDocumentTypesPerInstitution,
  };
}
