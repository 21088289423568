<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="480"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title>
          <v-container class="ma-0 pa-0">
            <v-row>
              <v-col class="d-flex mt-n2">
                <div
                  class="d-inline-block align-self-start text-subtitle-1"
                  style="width: 80%"
                >
                  {{ title }}
                </div>
                <div
                  class="d-inline-block text-right align-self-start"
                  style="width: 20%"
                >
                  <v-icon
                    class="mt-n2 mr-n2"
                    @click="hideDialog()"
                  >
                    mdi-close
                  </v-icon>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>

        <v-card-text>
          <slot />
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="teal darken-1"
            class="white--text"
            @click="hideDialog()"
          >
            {{$t('generic.ok')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { hideDialog } from '@/helpers/dialogHelper';

export default {
  name: 'FeatureTeaser',

  props: {
    title: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      dialog: true,
    };
  },

  methods: {
    hideDialog,
  },
};
</script>

<style scoped lang="scss">

  p {
    margin: 1em 0;
  }

</style>
