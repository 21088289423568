//
// This file is a direct copy from the shared `module_service.js`
// MAKE SURE TO KEEP THESE FILES IDENTICAL WHEN CHANGES ARE MADE!
// VVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV

/**
* Helper function to check if a user has access to a required module,
* possibly with additional configuration requirements (such as specific stakeholder source id's).
*
* @param {[object]} modules - The active modules the user has access to.
* @param {object} requiredModule - The required module,
* and any further requirements for that module.
* @returns {boolean} True if the user has the required module with the right configuration,
* false otherwise.
*/
const checkIfUserHasRequiredModule = (modules, requiredModule) => {
  if (!modules) { return false; }

  // Check if the user has the module at all
  const matchingModule = modules
    .find((mod) => mod.moduleStatus.moduleCode === requiredModule.code);

  // If the module was not found, the user is not allowed access by default
  if (!matchingModule) {
    return false;
  }

  // Check if the module has any further configuration requirements
  const { configuration } = requiredModule;
  if (!configuration) {
    return true;
  }

  // If there are further requirements, loop over the list of requirements and check each one
  return Object.keys(configuration).every((configParam) => {
    // Required stakeholder source IDs can be a subset of the full set the user has access to,
    // therefore we can't do a simple 'equals' and have to parse it separately
    if (configParam === 'stakeholderSourceIds') {
      return configuration.stakeholderSourceIds
        .every((ssid) => matchingModule.moduleConfiguration.stakeholderSourceIds
          && matchingModule.moduleConfiguration.stakeholderSourceIds.includes(ssid));
    }
    // Same for media source IDs
    if (configParam === 'mediaSourceIds') {
      return configuration.mediaSourceIds
        .every((ssid) => matchingModule.moduleConfiguration.mediaSourceIds
          && matchingModule.moduleConfiguration.mediaSourceIds.includes(ssid));
    }
    // Same for docTypes
    if (configParam === 'docTypes') {
      return configuration.docTypes
        .every((docType) => matchingModule.moduleConfiguration.docTypes
          && matchingModule.moduleConfiguration.docTypes.includes(docType));
    }

    // NOTE: if there are any other exceptions like the above, they should be added here!

    // For all other configuration options, we simply check if they exist and are equal:
    return (matchingModule.moduleConfiguration[configParam]
      && matchingModule.moduleConfiguration[configParam] === configuration[configParam]);
  });
};

module.exports = {
  checkIfUserHasRequiredModule,
};

// ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
// This file is a direct copy from the shared `module_service.js`
// MAKE SURE TO KEEP THESE FILES IDENTICAL WHEN CHANGES ARE MADE!
//
